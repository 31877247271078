@import "../../../assets/scss/partials/_colors";

.progress {
  position: relative;
  overflow: visible!important;
  overflow-x: hidden;
}

.progressBarFrame{
  border: 3px solid $gray-300;
}

.progressBar {
  z-index: 2;
  position: relative;
  overflow: visible!important;
  overflow-x: hidden;
  transition: width 2.4s ease-out!important;
  box-shadow: 0 0 0 $primary;
}

.progressText {
  color: $gray-900;
  position: absolute;
  left: 16px;
  bottom: 0;
  top: 0;
  margin: auto;
  height: fit-content;
  z-index: 20;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent;
  transition: all 400ms ease !important;
}

.badge {
  top: -3rem;
  position: absolute;
  z-index: 50;
  left: 100%;
  transition: transform 1.6s ease;
}

.pointer {
  position: absolute;
  left: 100%;
  right: -20px;
  transform-origin: 50% 100%;
  clip-path: polygon(100% 0%, 0% 0%, 50% 100%);
  margin-left: -10px;
  transition: transform 1.6s ease;
  background: $secondary;
  width: 20px;
  height: 30px;
  top: -30px;
}

.thresholdPointer {
  position: absolute;
  clip-path: polygon(100% 0%, 0% 0%, 50% 100%);
  margin-left: -3px;
  background: $gray-300;
  width: 6px;
  height: 6px;
  z-index: 10;
  top: 2px;
}

.progressLabels {
  position: relative;
}

.thresholdLabel {
  display: inline-block;
  position: absolute;
  transform: translate(-50%, 0.8rem);
  color: $gray-300;
  left: 45%;
}

.iconAnimation {
  animation: colorChange 0.05s cubic-bezier(0.22, 0.61, 0.36, 1)  forwards; // Apply the animation with duration and easing
}

@keyframes colorChange {
  90% {
    color: $gray-300; // Initial color
  }
  100% {
    color: $progress-icon; // Final color
  }
}
