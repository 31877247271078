.BadgeHeartOutline,
.BadgeContainer {
  top: .5rem;
}

.BadgeContainer {
  transform-origin: 50% top;
}

.BadgeOutline {
  top: .5rem;
}

.Z1 {
  z-index: 1;
}

.Z2 {
  z-index: 2;
}

.Z0 {
  z-index: 0;
}

/* Define keyframes for swingingIconAnimation with random direction */
@keyframes swingingIconAnimation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Define keyframes for swingingRibbonAnimation with random direction */
@keyframes swingingRibbonAnimation {
  0% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Apply animation to .AnimatedIcon and .AnimatedRibbon */
.AnimatedIcon {
  animation: swingingIconAnimation 2.4s ease-in-out forwards;
  animation-direction: reverse; /* Default animation direction */
}

.AnimatedRibbon {
  animation: swingingRibbonAnimation 2s ease-in-out forwards;
  animation-direction: normal; /* Default animation direction */
}
