@import "../../../assets/scss/partials/_colors";

.CardImage {
  width: 100%;
  padding-top: 75%;
  background-size: cover;
}

$iphone-rim: #dddddd;
$iphone-frame: #f5f5f5;

.midFrame {
  z-index: 10000;
  position: relative;
  margin: 8px auto;
  width: 270px;
  height: 564px;
  border: solid 1px $iphone-frame;
  background-color: $iphone-frame;
  border-radius: 50px;
}

.innerFrame {
  overflow: hidden;
  z-index: 2;
  position: relative;
  margin: 8px auto;
  width: 252px;
  height: 544px;
  border: solid 1px $iphone-rim;
  background-color: #ffffff;
  border-radius: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // home button indicator
  &:after {
    z-index: 1;
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #ffffff;
    border-radius: 10px;
  }

  // frontal camera/speaker frame
  &:before {
    border: 1px solid $iphone-rim;
    z-index: 1;
    border-top: none;
    top: 0;
    width: 56%;
    height: 24px;
    background-color: $iphone-frame;
    border-radius: 0 0 48px 48px;
  }

  i,
  b,
  s,
  span {
    position: absolute;
    display: block;
    color: transparent;
  }

  // speaker
  i {
    border: 1px solid $iphone-rim;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 8px);
    height: 8px;
    width: 15%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }

  // camera
  b {
    left: 5%;
    z-index: 1;
    top: 0;
    transform: translate(150px, 5px);
    width: 12px;
    height: 12px;
    border: solid 1px $iphone-rim;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);

    &:after {
      content: '';
      position: absolute;
      background-color: #ffffff;
      border: solid 1px $iphone-rim;

      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(255, 255, 255, 0.5);
    }
  }
}
